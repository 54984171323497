import '@adyen/adyen-web/dist/adyen.css'
import React, { useRef } from 'react'
import { useCheckout } from '../hooks/useCheckout'
import { useOffers } from '../hooks/useOffers'

interface IProps {
  couponCode?: string
  titleOverride?: string
}

export const Checkout: React.FC<IProps> = ({ couponCode, titleOverride }) => {
  const dropinRef = useRef<HTMLDivElement>(null)

  const {
    isPickOfferFirstFlow,
    offers,
    offersLoading,
    selectedOffer,
    setSelectedOffer,
  } = useOffers()

  const { order, paymentError, paymentLoading, handleClickPaypal } =
    useCheckout(dropinRef, couponCode)

  const handleOfferSelection = (offer: typeof offers[0]) => {
    setSelectedOffer(offer)
  }

  return (
    <>
      <h3>Checkout</h3>
      <fieldset>
        <legend className="hide">Choose your plan:</legend>
        {offersLoading ? (
          <p>&nbsp;</p>
        ) : isPickOfferFirstFlow && selectedOffer ? (
          <div>
            <input type="radio" checked disabled hidden />
            <label className="chosen-plan">
              {titleOverride ?? selectedOffer.offerTitle}
            </label>
          </div>
        ) : (
          offers.map((offer) => (
            <div key={offer.offerId}>
              <input
                type="radio"
                id={offer.offerId}
                name="offer"
                value={offer.offerId}
                checked={offer.offerId === selectedOffer?.offerId}
                disabled={paymentLoading}
                onChange={(e) => {
                  if (e.currentTarget.checked) {
                    handleOfferSelection(offer)
                  }
                }}
              />
              <label htmlFor={offer.offerId}>
                {offer.offerTitle}
              </label>
            </div>
          ))
        )}
      </fieldset>
      {!order ? (
        <div className="d-flex justify-content-center align-items-center">
          <div className="spinner-border text-orange" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          {(couponCode || '').toUpperCase() === 'PMPT12M44A' ? (
              <div>Price: $41.99/year</div>
            ) : (couponCode || '').toUpperCase() === 'TREAT256M' ? (
              <div>Price after promo: $9.99/month</div>
            ) : (
              <div>
                Price after trial: {order.currency} {selectedOffer?.offerCurrencySymbol ?? ''}{order.priceBreakdown.offerPrice}
                {selectedOffer?.period === 'month' && '/month'}
                {selectedOffer?.period === 'year' && '/year'}
              </div>
            )
          }
          {order.priceBreakdown.taxValue ? (
            <div className="hide">
              Applicable VAT: {order.priceBreakdown.taxValue} {order.currency}
            </div>
          ) : null}
          {order.priceBreakdown.paymentMethodFee ? (
            <div className="hide">
              Payment Method Fee: {order.priceBreakdown.paymentMethodFee}{' '}
              {order.currency}
            </div>
          ) : null}
          {order.priceBreakdown.discountAmount ? (
            <div className="hide">
              Discount Amount: -{order.priceBreakdown.discountAmount}{' '}
              {order.currency}
            </div>
          ) : null}
          {
  (couponCode || '').toUpperCase() === 'PMPT12M44A' ? (
    <div className="hide"> &nbsp; </div>
  ) : (
    <div>
      <span style={{ fontWeight: '700' }}>
        Due Today: {order.currency} {selectedOffer?.offerCurrencySymbol ?? ''}{order.totalPrice}
      </span>
    </div>
  )
}

          
          {couponCode && order.discount?.applied ? (
            <div className="coupon-display" style={{marginTop:'20px'}}>
              <div><span className='textbefore'>Coupon Code Applied:</span> <span className='coupontext'>{couponCode}</span></div>
            </div>
          ) : null}
        </>
      )}
      <div id="dropin-container">
        {paymentLoading ? <p>&nbsp;</p> : null}
        {paymentError ? (
          <p style={{ color: 'red' }}>
            There's been a problem with your payment method. Please check the information below.
          </p>
        ) : null}
        <div
          ref={dropinRef}
          style={{ visibility: paymentLoading ? 'hidden' : 'visible' }}
        />
      </div>
      {paymentLoading || !order ? null : (
        <div>
          <button onClick={handleClickPaypal} className="btn-paypal hide">
            Pay with PayPal
          </button>
        </div>
      )}
    </>
  )
}