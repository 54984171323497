import { createContext, PropsWithChildren, useEffect, useState } from 'react'
import { useCleeng } from '../hooks/useCleeng'
import { isProduction } from '../utils/env'

export const OFFER_IDS: string[] = isProduction()
  ? ['S247726991', 'S657822502', 'S623526602']
  : ['S621158629', 'S998638088', 'P542795762']
{/*old lifetime offer id S832593146 */}
interface IOffersContext {
  isPickOfferFirstFlow: boolean
  offers: IOffer[]
  offersLoading: boolean
  selectedOffer?: IOffer
  setIsPickOfferFirstFlow: React.Dispatch<React.SetStateAction<boolean>>
  setSelectedOffer: React.Dispatch<React.SetStateAction<IOffer | undefined>>
}

export const OffersContext = createContext<IOffersContext | null>(null)

export const OffersContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [offers, setOffers] = useState<IOffer[]>([])
  const [selectedOffer, setSelectedOffer] = useState<IOffer>()
  const [offersLoading, setOffersLoading] = useState(true)

  // if true, it means the user is on
  // the "pick offer first" flow
  // instead of the "register first" flow
  const [isPickOfferFirstFlow, setIsPickOfferFirstFlow] = useState(false)

  const { fetchOfferDetails } = useCleeng()

  useEffect(() => {
    const fetchData = async () => {
      setOffersLoading(true)

      const o: IOffer[] = []

      for (const offerId of OFFER_IDS) {
        const { responseData } = await fetchOfferDetails(offerId)

        if (responseData) {
          o.push(responseData)
        }
      }

      setOffers(o)
      setSelectedOffer(o.find((o) => o.period === 'year'))
      setOffersLoading(false)
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <OffersContext.Provider
      value={{
        isPickOfferFirstFlow,
        offers,
        offersLoading,
        selectedOffer,
        setIsPickOfferFirstFlow,
        setSelectedOffer,
      }}
    >
      {children}
    </OffersContext.Provider>
  )
}
